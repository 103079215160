<template>
	<div class="user flex main other_main">
		<asiden :asideData="asideList"></asiden>
		<div class="main_r">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import asiden from "../layout/aside/aside.vue";
export default {
	components: { asiden },
	name: "",
	data() {
		return {
			asideList: [
				{
					title: "推荐banner管理",
					icon: "",
					router: "/recommend/bannerlist",
				},
				// {
				// 	title: "活动banner管理",
				// 	icon: "",
				// 	router: "/recommend/eventbanner",
				// },
				// {
				// 	title: "活动calendar管理",
				// 	icon: "",
				// 	router: "/recommend/eventcalendar",
				// },
				{
					title: "活动置顶管理",
					icon: "",
					router: "/recommend/eventtop",
				},
			],
		};
	},
};
</script>

<style scoped></style>
